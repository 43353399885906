import React from 'react'

const Footer = () => {
    return (
        <div className='footer-bg'>
            © {new Date().getFullYear()}, Built with by
            <a href="https://https://webfunnel.io"> Web Funnel</a>
        </div>
    )
}

export default Footer
