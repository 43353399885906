import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from "gatsby-plugin-image"


const query = graphql`
  {
    file(relativePath: {eq: "blob-blue-tc.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Header = ({ siteTitle }) => {

  const { file: { childImageSharp: { fluid: imageData } } } = useStaticQuery(query)


  return (
    <header className='full-box'>
      <div className='left-hero'>
        <h1>
        Cebu Car Rental
        </h1>
        <h6>CONVENIENT TRANSPORTATION ANYWHERE IN CEBU</h6>
        <a href='#contact-id'><button className='btn'>Contact Us</button></a>
      </div>
      <BackgroundImage
        Tag='div'
        className='right-hero'
        fluid={imageData}
        style={{
          // Defaults are overwrite-able by setting one or each of the following:
          backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',

        }}
      >
        <StaticImage
          src="../images/grandia-hero.png"
          alt="A grandia van"
          placeholder="dominantColor"
          layout="constrained"
          width={900}
          // height={400}
          loading='eager'
          transformOptions={{fit: 'cover', cropFocus: 'attention'}}
          className='hero-van'
        />
      </BackgroundImage>
  </header>
  )

}
  
  
  


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}



export default Header
